$('.nav').css({
    left: '-100%'
  });
  
  
  $('.fa-bars').on('click', function(){
    
    $('.nav').show();
    $('.nav').animate({
        left: '0%'
    }, 'swing');
    
    $('body').addClass("main2");
    
  })
  
  
  $('.cerrar').on('click', function(){
    
    $('.nav').animate({
        left: '-100%'
    }, 'swing');
    $('body').removeClass("main2");
    
  })